const AppReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ITEM":
      const searchResult = state.cartItems.find(
        (check) => check.name === action.payload
      );
      if (searchResult) {
        const newItems = state.cartItems.filter(
          (item) => item.name !== searchResult.name
        );
        const item = {
          name: searchResult.name,
          quantity: (searchResult.quantity += 1),
        };
        console.log(item);
        newItems.unshift(item);

        return {
          ...state,
          cartItems: [...newItems],
        };
      } else {
        console.log("else");
        return {
          ...state,
          cartItems: [
            { name: action.payload, quantity: 1 },
            ...state.cartItems,
          ],
        };
      }
    case "CLEAR_CART":
      return {
        cartItems: [],
      };
    default:
      return state;
  }
};

export default AppReducer;
