import React, { useState, useContext } from "react";

import { ButtonBase, Grid, Divider } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

import { products } from "../products";
import { GlobalContext } from "../context/GlobalState";

const categories = ["Soft Drinks", "Beers", "Spirits", "Bottles"];

const useStyles = makeStyles((theme) =>
  createStyles({
    outline: {
      marginRight: 10,
    },
    categoryBox: {
      marginBottom: 10,
      position: "relative",
      padding: 20,
      borderRadius: 10,
      color: "#000",
      fontWeight: "bold",
      fontSize: 24,
      backgroundColor: "#F2F2F2",
      "&:hover, &$focusVisible": {
        zIndex: 1,
        "& $imageBackdrop": {
          opacity: 0.15,
        },
        "& $imageMarked": {
          opacity: 0,
        },
        "& $imageTitle": {
          border: "4px solid currentColor",
        },
      },
    },
    active: {
      border: "5px solid transparent",
      borderImage: "linear-gradient(to right, #2E4BFC, #9600AF)",
      borderImageSlice: 1,
    },
    item: {
      height: 150,
      width: 150,
      color: "#FFF",
      fontWeight: "bold",
      fontSize: 18,
      marginRight: 10,
      marginBottom: 10,
      borderRadius: 10,
    },
  })
);

const CategoryList = () => {
  const classes = useStyles({});

  const [index, setIndex] = useState(0);
  const { addToCart } = useContext(GlobalContext);

  const addItemsToCart = (item) => {
    addToCart(item);
  };

  const product = products[index];

  const colors = ["#1C4854", "#00A28E", "#EFC161", "#FF995A"];

  return (
    <>
      <Grid container direction="column">
        <Grid item alignSelf="flex-start" justifySelf={"flex-start"}>
          {categories.map((category, i) => (
            <span className={classes.outline} key={i}>
              <ButtonBase
                focusRipple
                key={category}
                className={classes.categoryBox}
                onClick={() => setIndex(i)}
                style={{
                  border: `${
                    index === i ? "5px solid transparent" : "transparent"
                  }`,
                  borderImage: `${
                    index === i
                      ? "linear-gradient(to right, #2E4BFC, #9600AF)"
                      : "transparent"
                  }`,
                  borderImageSlice: `${index === i ? 1 : 0}`,
                  borderRadius: `${index === i ? 10 : 0}`,
                }}
              >
                {category}
              </ButtonBase>
            </span>
          ))}
        </Grid>
        <Grid item>
          <br />
        </Grid>
        <Divider />
        <Grid item>
          <br />
        </Grid>
        <Grid item alignSelf="flex-start" justifySelf={"flex-start"}>
          {product.map((item, i) => (
            <ButtonBase
              key={i}
              focusRipple
              className={classes.item}
              onClick={() => addItemsToCart(item)}
              style={{
                backgroundColor: `${
                  i >= 6 && i <= 11
                    ? colors[1]
                    : i >= 12 && i <= 17
                    ? colors[2]
                    : i >= 18 && i <= 23
                    ? colors[3]
                    : colors[0]
                }`,
              }}
            >
              {item}
            </ButtonBase>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default CategoryList;
