export const products = [
  [
    "Coke Zero",
    "Diet Pepsi",
    "Sprite",
    "Soda Water",
    "Coke Zero",
    "Coke Zero",
    "Red Bull",
    "7up Free",
    "J20 Apple Raspberry",
    "J20 Orange PassionFruit",
    "J20 Apple Mango",
    "Orange Juice",
    "Tonic Water 200ml",
    "Apple Juice",
    "Cranberry Juice",
    "Bitter Lemon",
    "Old Jamica Ginger Beer",
    "Emerge Energy",
    "Strathmore Still Water",
    "Strathmore Sparkling Water",
    "Tomator Juice",
    "Pineapple Juice 200ml",
    "Ginger Ale",
    "Pink Soda",
  ],
  [
    "Red Bull",
    "7up Free",
    "J20 Apple Raspberry",
    "J20 Orange PassionFruit",
    "J20 Apple Mango",
    "Orange Juice",
    "Tonic Water 200ml",
    "Ginger Ale",
    "Pink Soda",
  ],
  [
    "Cranberry Juice",
    "Bitter Lemon",
    "Old Jamica Ginger Beer",
    "Emerge Energy",
    "Strathmore Still Water",
    "Strathmore Sparkling Water",
    "Tomator Juice",
    "Pineapple Juice 200ml",
    "Ginger Ale",
    "Pink Soda",
  ],
  [
    "Coke Three",
    "Diet Pepsi",
    "Sprite",
    "Soda Water",
    "Coke Zero",
    "Coke Zero",
  ],
];
