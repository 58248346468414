import React from "react";

import { Grid, Divider } from "@mui/material";
import UserInfo from "./UserInfo";
import CategoryList from "./CategoryList";

const DetailsPanel = () => {
  return (
    <div style={{ padding: 10 }}>
      <Grid container direction="column">
        <Grid item>
          <UserInfo />
        </Grid>
        <Grid item>
          <br />
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <br />
        </Grid>
        <Grid item alignSelf="flex-start">
          <CategoryList />
        </Grid>
      </Grid>
    </div>
  );
};

export default DetailsPanel;
