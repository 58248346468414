import React, { useContext } from "react";

import { Button, Container, Grid, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

import { GlobalContext } from "../context/GlobalState";

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      backgroundColor: "#FFF",
      padding: 10,
      borderRadius: 10,
    },
  })
);

const CategoryAction = () => {
  const classes = useStyles({});

  const { clearCart } = useContext(GlobalContext);

  return (
    <Container className={classes.box}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h6" component="div" color="#000">
            <strong>Create an Order</strong>
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={1}
          >
            <Grid item>
              <Button
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  backgroundColor: "#F7F8FB",
                  color: "#000",
                  width: 100,
                }}
              >
                Add Note
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={clearCart}
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  backgroundColor: "#F7F8FB",
                  color: "#000",
                  width: 120,
                }}
              >
                Clear Items
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={1}
          >
            <Grid item>
              <Button
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  backgroundColor: "#2A4CFF",
                  width: 100,
                }}
              >
                On Table
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  backgroundColor: "#9700AE",
                  width: 120,
                }}
              >
                Pay Now
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CategoryAction;
