import React, { useContext } from "react";

import { Container, Divider, Grid, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

import { GlobalContext } from "../context/GlobalState";

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      backgroundColor: "#FFF",
      padding: 10,
      borderRadius: 10,
      height: "81vh",
      overflow: "scroll",
    },
  })
);

const CartItem = () => {
  const classes = useStyles({});

  const { cartItems } = useContext(GlobalContext);

  return (
    <Container className={classes.box}>
      <Grid container direction="column" spacing={1}>
        {cartItems && cartItems.length <= 0 ? (
          <p>No Items founds</p>
        ) : (
          cartItems.map((item, i) => (
            <div key={i}>
              <Grid item style={{ padding: 4 }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item>
                    <Grid container direction="column" alignItems="flex-start">
                      <Grid item>
                        <Typography variant="p" component="div" color="#000">
                          <strong>
                            {item.quantity}x {item.name}
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="caption"
                          component="div"
                          color="#000"
                        >
                          Half pint, No ice
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="p" component="div" color="#000">
                      <strong>&#163;9.25</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Divider />
              </Grid>
            </div>
          ))
        )}
      </Grid>
    </Container>
  );
};

export default CartItem;
