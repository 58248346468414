import React from "react";

import { Grid } from "@mui/material";
import SubTotal from "./SubTotal";
import CategoryAction from "./CategoryAction";
import CartItem from "./CartItem";

const ActionPanel = () => {
  return (
    <div
      style={{
        background: "linear-gradient(to right, #2E4BFC, #9600AF)",
        padding: 10,
      }}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <CartItem />
        </Grid>
        <Grid item>
          <SubTotal />
        </Grid>
        <Grid item>
          <CategoryAction />
        </Grid>
      </Grid>
    </div>
  );
};

export default ActionPanel;
