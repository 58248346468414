import React from "react";

import { Grid, Typography, Link } from "@mui/material";

const UserInfo = () => {
  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container direction="column" alignItems="flex-start">
            <Grid item>
              <Typography variant="h5" component="div" color="#000">
                <strong>Drunken Goose</strong>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" display="block">
                Regent Street, London, WC1275
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="flex-start">
            <Grid item>
              <Typography variant="subtitle2" component="div" color="#000">
                Logged in as Gary Tinkler
              </Typography>
            </Grid>
            <Grid item>
              <Link href="#" underline="hover" color="#9600AF">
                Help&nbsp;
              </Link>
              <span style={{ color: "#9600AF" }}>|&nbsp;</span>
              <Link href="#" underline="hover" color="#9600AF">
                Log Out
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UserInfo;
