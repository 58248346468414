import React from "react";

import { Container, Grid, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      backgroundColor: "#FFF",
      padding: 10,
      borderRadius: 10,
    },
  })
);

const SubTotal = () => {
  const classes = useStyles({});

  return (
    <Container className={classes.box}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Typography variant="h5" component="div" color="#000">
            <strong>Total</strong>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5" component="div" color="#000">
            <strong>&#163;9.25</strong>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SubTotal;
