import React from "react";
import AppReducer from "./AppReducer";

const initialState = {
  cartItems: [],
};

export const GlobalContext = React.createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(AppReducer, initialState);

  const addToCart = (cartItem) => {
    dispatch({
      type: "ADD_ITEM",
      payload: cartItem,
    });
  };

  const clearCart = (cartItem) => {
    dispatch({
      type: "CLEAR_CART",
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        cartItems: state.cartItems,
        addToCart,
        clearCart,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
