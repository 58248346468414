import * as React from "react";

import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import DetailsPanel from "./components/DetailsPanel";
import ActionPanel from "./components/ActionPanel";
import { GlobalProvider } from "./context/GlobalState";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderRadius: 10,
}));

function App() {
  return (
    <GlobalProvider>
      <>
        <Grid container spacing={2}>
          <Grid item md={9} xs={12}>
            <Item>
              <DetailsPanel />
            </Item>
          </Grid>
          <Grid item md={3} xs={12}>
            <Item>
              <ActionPanel />
            </Item>
          </Grid>
        </Grid>
      </>
    </GlobalProvider>
  );
}

export default App;
